import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { UserInfo, TcbObjInfo, MenuItemModel, TcbObjNoteGroup, TcbObjNote } from '../../models/models';
import { loadingDiv } from '../../functions/componentFunctions';
import { addPageViewClass } from '../../functions/generalFunctions'
import { mapStateToProps, mapDispatchToProps } from '../../redux/reduxActions';
import ErrorMsg from '../ErrorMsg';
import { Menu, MenuSelectEvent } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import moment from 'moment';

import './TcbObjNotes.css';

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;


type TcbObjNotesProps = PropsFromRedux & {
    tcbObj: TcbObjInfo;
    canEdit: boolean;
    stateToLoad?: TcbObjNotesState;
    onSaveState?: (e: TcbObjNotesState) => void;
}

interface TcbObjNotesState {
    searchInProg: boolean;

    tcbObjNoteGrps: TcbObjNoteGroup[];
    notesFetched: boolean;
    fetchError: boolean;
    fetchErrorMsg: string;

}

class TcbObjNotes extends React.Component<TcbObjNotesProps, TcbObjNotesState> {
    constructor(props: TcbObjNotesProps) {
        super(props);

        if (this.props.stateToLoad) {
            this.state = { ...this.props.stateToLoad };
        } else {

            this.state = {
                searchInProg: true,
                tcbObjNoteGrps: [],
                notesFetched: false,
                fetchError: false,
                fetchErrorMsg: '',
            };
        }


    }


    componentDidMount() {
        if (!this.state.notesFetched) {
            this.getTcbObjNotes();
        }
    }

    componentDidUpdate(prevProps: TcbObjNotesProps) {
    }


    getTcbObjNotes = () => {
        this.setState({ tcbObjNoteGrps: [], searchInProg: true });

        let url = this.props.userInf.currProject.apiUrl + '/api/details/GetTcbObjNotes';

        fetch(url, { method: 'POST', body: JSON.stringify(this.props.tcbObj), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        let ngs: TcbObjNoteGroup[] = res.results;
                        ngs.forEach(g => {
                            g.notes.forEach(n => {
                                if (n.created) {
                                    let cr = moment(n.created);
                                    n.created = cr;
                                }
                            })
                        })
                        this.setState({
                            tcbObjNoteGrps: res.results,
                            notesFetched: true,
                            searchInProg: false
                        }, () => { if (this.props.onSaveState) this.props.onSaveState(this.state) });
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        this.setState({ searchInProg: false });
                        break;

                    default:
                        this.setState({
                            searchInProg: false,
                            fetchError: true,
                            fetchErrorMsg: res.callStatusMessage
                        });
                }
            })
            .catch(err => {
                this.setState({
                    searchInProg: false,
                    fetchError: true,
                    fetchErrorMsg: 'Error fetching Item Details - ' + err.toString()
                });
            });

    }

    errorClose = () => {
        this.setState({ fetchError: false, fetchErrorMsg: '' });
    }


    contextGroupMenuClick = (m: MenuSelectEvent, grpId: number) => {
        switch (m.item['action']) {
            case "addNote":
                this.addNote(grpId);
                break;
            case "delLastNote":
                this.deleteLastNote(grpId);
                break;
        }

    }


    toggleNoteGroupExpand = (grpId: number) => {
        let ngs = this.state.tcbObjNoteGrps;
        let grp = ngs.find(x => x.noteGrpId === grpId);
        if (grp) {
            grp.isExpanded = !grp.isExpanded;
            this.setState({ tcbObjNoteGrps: ngs });
        }
    }

    addNote = (grpId: number) => {
        //Does new note already exist
        let ngs = this.state.tcbObjNoteGrps;
        let ng = ngs.find(x => x.noteGrpId === grpId)!;
        if (ng.notes.some(x => x.noteId === ''))
            return;

        let newNote: TcbObjNote = { noteId: '', noteGrpId: grpId, author: this.props.userInf.username, created: moment(), noteTxt: '', noteTxtSave: '', canEdit: true, editView: true, saveInProgress: false };
        ng.notes.unshift(newNote);
        this.setState({ tcbObjNoteGrps: ngs });
    }

    editNote = (n: TcbObjNote) => {
        if (n.canEdit) {
            let ngs = this.state.tcbObjNoteGrps;
            let ng = ngs.find(x => x.noteGrpId === n.noteGrpId);
            if (ng) {
                let nt = ng.notes.find(x => x.noteId === n.noteId);
                if (nt) {
                    nt.editView = true;
                    nt.noteTxtSave = nt.noteTxt;
                    nt.saveInProgress = false;
                    this.setState({ tcbObjNoteGrps: ngs });
                }
            }
        }
    }

    deleteLastNote = (grpId: number) => {

        //Does new note already exist
        let ngs = this.state.tcbObjNoteGrps;
        let ng = ngs.find(x => x.noteGrpId === grpId)!;

        if (ng && ng.notes.length > 0) {
            let n = ng.notes[0];
            if (n.canEdit) {


                n.saveInProgress = true;
                this.setState({ tcbObjNoteGrps: this.state.tcbObjNoteGrps });

                let url = this.props.userInf.currProject.apiUrl + '/api/details/DeleteTcbObjNote';
                let body = {
                    tcbObj: this.props.tcbObj,
                    tcbObjAttributeId: grpId,
                }

                fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
                    .then(resp => resp.json())
                    .then(res => {
                        switch (res.callStatus) {
                            case "OK":
                                ng.notes.splice(0, 1);
                                this.setState({ tcbObjNoteGrps: ngs });

                                break;
                            case "UNAUTH":
                                let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                                this.props.updateUserInfo(uInf);
                                this.setState({ searchInProg: false });
                                break;

                            default:
                                throw (res.callStatusMessage);
                        }
                    })
                    .catch(err => {
                        alert(err.toString());
                        n.saveInProgress = false;
                        this.setState({ tcbObjNoteGrps: this.state.tcbObjNoteGrps }, () => { if (this.props.onSaveState) this.props.onSaveState(this.state) });
                    });
            }
        }
    }


    editNoteOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>, n: TcbObjNote) => {

        let ngs = this.state.tcbObjNoteGrps;
        let ng = ngs.find(x => x.noteGrpId === n.noteGrpId);
        if (ng) {
            let nt = ng.notes.find(x => x.noteId === n.noteId);
            if (nt) {
                nt.noteTxt = e.target.value;
                this.setState({ tcbObjNoteGrps: ngs });
            }

        }
    }

    editNoteCancelSave = (n: TcbObjNote) => {
        let ngs = this.state.tcbObjNoteGrps;
        let ng = ngs.find(x => x.noteGrpId === n.noteGrpId)!;

        if (n.noteId === '') {
            ng.notes = ng.notes.filter(x => x.noteId !== n.noteId);
        } else {
            n.noteTxt = n.noteTxtSave;
            n.editView = false;
        }
        this.setState({ tcbObjNoteGrps: ngs });
    }

    editNoteCommitSave = (n: TcbObjNote) => {
        n.saveInProgress = true;
        this.setState({ tcbObjNoteGrps: this.state.tcbObjNoteGrps });

        let url = this.props.userInf.currProject.apiUrl + '/api/details/AddTcbObjNote';
        let body = {
            tcbObj: this.props.tcbObj,
            tcbObjAttributeId: n.noteGrpId,
            noteId: n.noteId,
            noteTxt: n.noteTxt
        }

        fetch(url, { method: 'POST', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.props.userInf.token } })
            .then(resp => resp.json())
            .then(res => {
                switch (res.callStatus) {
                    case "OK":
                        n.noteId = res.stringResponse;
                        n.saveInProgress = true;
                        n.editView = false;
                        this.setState({ tcbObjNoteGrps: this.state.tcbObjNoteGrps }, () => { if (this.props.onSaveState) this.props.onSaveState(this.state) });
                        break;
                    case "UNAUTH":
                        let uInf: UserInfo = { ...this.props.userInf, isAuthorised: false };
                        this.props.updateUserInfo(uInf);
                        this.setState({ searchInProg: false });
                        break;

                    default:
                        throw (res.callStatusMessage);
                }
            })
            .catch(err => {
                alert(err.toString());
                n.saveInProgress = false;
                this.setState({ tcbObjNoteGrps: this.state.tcbObjNoteGrps }, () => { if (this.props.onSaveState) this.props.onSaveState(this.state) });
            });
    }


    renderNoteGroup = (grpId: number, title: string, canAdd: boolean, canDel: boolean, isExpanded: boolean) => {
        let menuItm: JSX.Element | null = null;
        if (canDel) {
            let itemMenuItms: MenuItemModel[] = [];
            let cxtMenu: MenuItemModel = { items: [], linkRender: () => { return <span id="contextMenuLinkSpan" className="k-icon k-font-icon k-i-more-vertical" /> } };
            itemMenuItms.push(cxtMenu);
            cxtMenu.items.push({ text: 'Add Note', action: 'addNote', cssClass: 'menuItem', disabled: false, items: [] });
            cxtMenu.items.push({ text: 'Delete Last Note', action: 'delLastNote', cssClass: 'menuItem', disabled: false, items: [] });

            menuItm = <Menu className="noteGroupMenu" items={itemMenuItms} openOnClick={true} vertical={true} onSelect={(m) => this.contextGroupMenuClick(m, grpId)} />;

        } else if (canAdd) {
            menuItm = <span className="k-icon k-font-icon k-i-track-changes-enable addItm" onClick={() => this.addNote(grpId)}></span>
        }

        let expandItm: JSX.Element | null = null;
        if (grpId !== -1) {
            if (isExpanded)
                expandItm = <span className="k-icon k-font-icon k-i-minus-outline expandItm" onClick={() => this.toggleNoteGroupExpand(grpId)} />;
            else
                expandItm = <span className="k-icon k-font-icon k-i-plus-outline expandItm" onClick={() => this.toggleNoteGroupExpand(grpId)} />;
        }

        // return <div className={"noteGrpMenuDiv" + addPageViewClass(this.props.pageInf)}>  onClick={() => this.noteGroupClick(grpId)} >
        return <div className={"noteGrpMenuDiv" + addPageViewClass(this.props.pageInf)}>
            {menuItm}
            {expandItm}
            <span className="noteGrpTitleSpan">{title}</span>
        </div>;

    }

    renderNote = (n: TcbObjNote, expanded: boolean) => {

        if (expanded) {

            let noteCtl: JSX.Element;
            if (n.editView) {
                let canSave = false;
                if (n.noteTxt.length > 0)
                    canSave = true;
                noteCtl = <div className="singleNoteTxtInp">
                    <textarea value={n.noteTxt} onChange={(e) => this.editNoteOnChange(e, n)} placeholder="Enter comment" ></textarea>
                    {!n.saveInProgress && <Button className="check" disabled={!canSave} onClick={() => this.editNoteCommitSave(n)} ><span className="k-icon k-font-icon k-i-check-outline" /></Button>}
                    {!n.saveInProgress && <Button className="close" onClick={() => this.editNoteCancelSave(n)} ><span className="k-icon k-font-icon k-i-close-outline" /></Button>}
                    {n.saveInProgress && <span className="inProg"><div>{loadingDiv()}</div></span>}
                </div>;
            } else {
                noteCtl = <span className="singleNoteTxt" onClick={() => this.editNote(n)}>{n.noteTxt}</span>;
            }

            return (<div className="singleNoteDiv" >
                <span className="singleNoteDate" onClick={() => this.editNote(n)}>{n.created.format("DD-MMM-YYYY HH:mm")}</span>
                <span className="singleNoteAuthor">{n.author}</span>
                {noteCtl}
            </div>
            )
        } else {
            return <div></div>
        }
    }


    renderNotes = () => {
        if (this.state.tcbObjNoteGrps.length > 0) {
            return (
                this.state.tcbObjNoteGrps.sort((x, y) => { return (x.sortOrder - y.sortOrder) }).map((x, i) => {
                    return (
                        <table key={i} className={"tcbObjNoteGroupTable" + addPageViewClass(this.props.pageInf)}>
                            <thead>
                                <tr>
                                    <td colSpan={2}>
                                        {this.renderNoteGroup(x.noteGrpId, x.noteGrpName, x.canEdit, x.canDelete, x.isExpanded)}
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="noteContainer">
                                        {x.notes.map((c) => { return this.renderNote(c, x.isExpanded) })}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    );
                }));
        } else {
            return (<div className="NoNotesMessage">There are no notes</div>);
        }
    }
    render() {
        if (this.state.fetchError) {
            return (<ErrorMsg message={this.state.fetchErrorMsg} onClose={this.errorClose} />);
        }
        if (this.state.searchInProg) {
            return loadingDiv();
        }

        return (
            <div id="TcbObjNotesDiv" className={addPageViewClass(this.props.pageInf)}>
                {this.renderNotes()}
            </div>
        );
    }

}

export default connector(TcbObjNotes);